import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[ClockIpAddress]';

export const clockIpAddressActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  REMOVE: `${ACTION_PREFIX} Remove`,
  REMOVE_SUCCESS: `${ACTION_PREFIX} Remove Success`,
  REMOVE_FAILED: `${ACTION_PREFIX} Remove Failed`,
};

export class ClockIpAddressAction {
  public static load(): Action {
    return {
      type: clockIpAddressActionType.LOAD,
    };
  }

  public static loadSuccess(clockIpAddress): Action {
    return {
      type: clockIpAddressActionType.LOAD_SUCCESS,
      payload: clockIpAddress,
    };
  }

  public static loadFailed(err): Action {
    return {
      type: clockIpAddressActionType.LOAD_FAILED,
      payload: err,
    };
  }

  public static add(clockIpAddressData): Action {
    return {
      type: clockIpAddressActionType.ADD,
      payload: clockIpAddressData,
    };
  }

  public static addSuccess(clockIpAddressResponse): Action {
    return {
      type: clockIpAddressActionType.ADD_SUCCESS,
      payload: clockIpAddressResponse,
    };
  }

  public static addFailed(err): Action {
    return {
      type: clockIpAddressActionType.ADD_FAILED,
      payload: err,
    };
  }

  public static update(clockIpAddressData): Action {
    return {
      type: clockIpAddressActionType.UPDATE,
      payload: clockIpAddressData,
    };
  }

  public static updateSuccess(clockIpAddressResponse): Action {
    return {
      type: clockIpAddressActionType.UPDATE_SUCCESS,
      payload: clockIpAddressResponse,
    };
  }

  public static updateFailed(err): Action {
    return {
      type: clockIpAddressActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  public static fetch(id: any) {
    return {
      type: clockIpAddressActionType.FETCH,
      payload: id,
    };
  }

  public static fetchSuccess(response) {
    return {
      type: clockIpAddressActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  public static fetchFailed(id: string, err) {
    return {
      type: clockIpAddressActionType.FETCH_FAILED,
      payload: {
        id,
        err,
      },
    };
  }

  public static remove(id: string) {
    return {
      type: clockIpAddressActionType.REMOVE,
      payload: id,
    };
  }

  public static removeSuccess(id: string) {
    return {
      type: clockIpAddressActionType.REMOVE_SUCCESS,
      payload: id,
    };
  }

  public static removeFailed(id: string, err) {
    return {
      type: clockIpAddressActionType.REMOVE_FAILED,
      payload: {
        id,
        err,
      },
    };
  }
}
