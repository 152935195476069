import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppState } from '../../index';
import { ClockIpAddressAction } from './clock-ip-address.action';
import { ClockIpAddressApi } from './clock-ip-address.api';
import { ClockIpAddressModel, ClockIpAddressState } from './clock-ip-address.model';

@Injectable()
export class ClockIpAddressService {
  public constructor(
    private store: Store<AppState>,
    private api: ClockIpAddressApi,
  ) {}

  public load() {
    this.store.dispatch(ClockIpAddressAction.load());

    return this.api.load().pipe(
      map((response) => {
        this.store.dispatch(ClockIpAddressAction.loadSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(ClockIpAddressAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public add(clockIpAddressData): Observable<any> {
    this.store.dispatch(ClockIpAddressAction.add(clockIpAddressData));

    return this.api.add(clockIpAddressData).pipe(
      map((response) => {
        this.store.dispatch(ClockIpAddressAction.addSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(ClockIpAddressAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public update(id, clockIpAddressData) {
    this.store.dispatch(ClockIpAddressAction.update(clockIpAddressData));

    return this.api.update(id, clockIpAddressData).pipe(
      map((response) => {
        this.store.dispatch(ClockIpAddressAction.updateSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(ClockIpAddressAction.updateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public fetch(id) {
    this.store.dispatch(ClockIpAddressAction.fetch(id));

    return this.api.fetch(id).pipe(
      map((response) => {
        this.store.dispatch(ClockIpAddressAction.fetchSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(ClockIpAddressAction.fetchFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public remove(id) {
    this.store.dispatch(ClockIpAddressAction.remove(id));

    return this.api.remove(id).pipe(
      map((response) => {
        this.store.dispatch(ClockIpAddressAction.removeSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(ClockIpAddressAction.removeFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public save(clockIpAddressData: ClockIpAddressModel) {
    const { id, ...data } = clockIpAddressData;
    if (clockIpAddressData.id) {
      return this.update(clockIpAddressData.id, clockIpAddressData);
    }
    return this.add(data);
  }
}

export const getClockIpAddressState = (appState: AppState): ClockIpAddressState => appState.orm.clockIpAddress;

export const getClockIpAddressIds = compose((state) => state.items, getClockIpAddressState);
export const getClockIpAddressEntities = createSelector(getClockIpAddressState, (state) => state.itemsById);
export const getClockIpAddresses = createSelector(getClockIpAddressState, (state: ClockIpAddressState) =>
  Object.values(state.itemsById),
);

export const getClockIpAddress = (id: string) => createSelector(getClockIpAddressEntities, (entities) => entities[id]);
