import { Injectable } from '@angular/core';
import { arrayOf, normalize } from 'normalizr';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiGateway } from '../../../api/ApiGateway.service';
import { assignSchemaEntity } from '../../shared/assign';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { ClockIpAddressSchema } from '../../shared/schemas';

@Injectable()
export class ClockIpAddressApi {
  private endpoint = 'clock_ips';

  public constructor(private gateway: ApiGateway) {}

  public load(): Observable<any> {
    return this.gateway.get(this.endpoint).pipe(
      map((res) => reformatListResponse('ClockIp', res)),
      map((data) => normalize(data, arrayOf(ClockIpAddressSchema), { assignEntity: assignSchemaEntity })),
    );
  }

  public add(clockIpAddressData): Observable<any> {
    return this.gateway.post(this.endpoint, clockIpAddressData).pipe(
      map((res) => reformatEntityResponse('ClockIp', res)),
      map((data) => normalize(data, ClockIpAddressSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public update(id: string, data): Observable<any> {
    return this.gateway.put(`${this.endpoint}/${id}`, data).pipe(
      map((res) => reformatEntityResponse('ClockIp', res)),
      map((data) => normalize(data, ClockIpAddressSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public fetch(id): Observable<any> {
    return this.gateway.get(`${this.endpoint}/${id}`).pipe(
      map((res) => reformatEntityResponse('ClockIp', res)),
      map((data) => normalize(data, ClockIpAddressSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public remove(id: string): Observable<any> {
    return this.gateway.delete(`${this.endpoint}/${id}`);
  }
}
